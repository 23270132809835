<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="query.clientId"
              :options="codes.clients"
              placeholder="제약사"
              :reduce="option => option.code"
              class="el-def"
              style="width:200px; margin-right:50px;"
            />

            <b-form-datepicker
              id="datepicker-placeholder1"
              v-model="query.startDate"
              class="el-def"
              placeholder="게시기간(Fr)"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              id="datepicker-placeholder2"
              v-model="query.endDate"
              class="el-def"
              placeholder="게시기간(To)"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="fetchEmailAsks()"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              콘텐츠 리스트 [{{ totalRecords }}] 건
            </h5>
          </b-col>
        </b-row>
      </div>
      <b-table
        primary-key="liveInfoId"
        :items="drugInfoList"
        :fields="tableColumns"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
        @row-selected="onRowSelectedEmailAsk"
      >
        <template #cell(title)="data">
          <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ data.value }}
          </div>
        </template>
      </b-table>
    </b-card>

    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <feather-icon
              icon="EditIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              상세
            </h4>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="outline-dark"
              class="btn-search"
              @click.prevent="excelDownload()"
            >
              <feather-icon
                icon="DownloadIcon"
              />
              <span> 다운로드</span>
            </b-button>
          </b-col>
        </b-row>

      </div>
      <b-table
        primary-key="liveInfoId"
        :items="emailAskList"
        :fields="detailTableColumns"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
        style="max-height: 330px;"
      >
        <template #cell(drugAskDetail)="data">
          <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ data.value }}
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import {
  ref, onMounted,
} from "@vue/composition-api"
import { useToast } from "vue-toastification/composition"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import vSelect from "vue-select"
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"

export default {
  components: {
    vSelect,
  },
  setup() {
    onMounted(() => {
      fetchCodes()
    })

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }
      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    // Variable
    const drugInfoList = ref([])
    const emailAskList = ref([])
    const totalRecords = ref(0)

    // Set Codes
    const codes = ref({
      clients: [],
    })

    // Query Data
    const query = ref({
      clientId: null,
      startDate: null,
      endDate: null,
    })

    // Main Table Config
    const tableColumns = [
      {
        key: "liveInfoId", label: "ID", sortable: true, thStyle: { width: '7%' },
      },
      {
        key: "clientName", label: "제약사명", sortable: true, thStyle: { width: '10%' },
      },
      { key: "title", label: "제목", sortable: true },
      {
        key: "liveDate", label: "게시기간", sortable: true, thStyle: { width: '15%' },
      },
      {
        key: "accessCode", label: "접속코드", sortable: true, thStyle: { width: '12%' },
      },
      {
        key: "liveTypeStr", label: "게시상태", sortable: true, thStyle: { width: '10%' },
      },
      {
        key: "askCount", label: "신청 수", sortable: true, thStyle: { width: '7%' },
      },
    ]

    const detailTableColumns = [
      {
        key: "id", label: "ID", sortable: true, thStyle: { width: '7%' },
      },
      {
        key: "fullName", label: "의사명", sortable: true, thStyle: { width: '7%' },
      },
      {
        key: "hcpWorkPlace", label: "의원명", sortable: true, thStyle: { width: '10%' },
      },
      {
        key: "hcpWorkAddress", label: "의원 주소", sortable: true, thStyle: { width: '20%' },
      },
      { key: "drugAskDetail", label: "문의내역", sortable: true },
      {
        key: "email", label: "이메일주소", sortable: true, thStyle: { width: '15%' },
      },
      {
        key: "createDateStr", label: "신청 일시", sortable: true, thStyle: { width: '12%' },
      },
    ]

    const emailAskInitState = {
      liveInfoId: null,
      title: null,
    }

    const emailAskData = ref({ ...emailAskInitState })

    const fetchCodes = () => {
      axios.get("/fa/email/codes")
        .then(rs => {
          const { clients } = rs.data
          codes.value.clients = clients
          fetchEmailAsks()
        })
        .catch(() => {
          pushToast('danger', '코드 데이터를 불러오는데 실패하였습니다.')
        })
    }

    const resetEmailAskData = () => {
      Object.assign(emailAskData.value, emailAskInitState)
    }

    const onRowSelectedEmailAsk = items => {
      const item = items[0]
      if (item) {
        resetEmailAskData()
        fetchEmailAsk(item.liveInfoId)
      }
    }

    const fetchEmailAsks = () => {
      axios
        .post("/fa/email/list", {
          search: {
            query: query.value,
          },
          sort: {
            predicate: 'liveInfoId',
            reverse: true,
          },
        })
        .then(rs => {
          const { items, totalRecord } = rs.data
          drugInfoList.value = items
          totalRecords.value = totalRecord
        })
        .catch(() => {
          pushToast('danger', '데이터 조회 중 오류가 발생하였습니다.')
        })
    }

    const fetchEmailAsk = id => {
      axios.post(`/fa/email/detail/${id}`, {
        search: {
        },
        sort: {
          predicate: 'id',
          reverse: true,
        },
      })
        .then(rs => {
          emailAskList.value = rs.data
        })
        .catch(() => {
          pushToast('danger', '데이터 조회 중 오류가 발생하였습니다.')
        })
    }

    // TODO
    // 내부망에서만 다운로드 시 예외처리 필요함
    const excelDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.columns = [
        { header: "No", key: "id", width: 15 },
        { header: "의사명", key: "fullName", width: 15 },
        { header: "의원명", key: "hcpWorkPlace", width: 30 },
        { header: "의원 주소", key: "hcpWorkAddress", width: 50 },
        { header: "문의내역", key: "drugAskDetail", width: 20 },
        { header: "이메일 주소", key: "email", width: 20 },
        { header: "신청 일시", key: "createDateStr", width: 20 },
      ]

      emailAskList.value.map(e => worksheet.addRow(e))

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }
        })
      })
      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()

      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "이메일 문의 내역.xlsx")
    }

    return {
      codes,
      fetchCodes,
      query,
      emailAskList,
      totalRecords,
      tableColumns,
      onRowSelectedEmailAsk,
      fetchEmailAsk,
      fetchEmailAsks,
      detailTableColumns,
      drugInfoList,
      excelDownload,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
