var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "liveinfo-form", attrs: { "no-body": "" } }, [
        _c(
          "div",
          { staticClass: "m-2" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                    attrs: { cols: "12", md: "10" },
                  },
                  [
                    _c("v-select", {
                      staticClass: "el-def",
                      staticStyle: { width: "200px", "margin-right": "50px" },
                      attrs: {
                        options: _vm.codes.clients,
                        placeholder: "제약사",
                        reduce: function (option) {
                          return option.code
                        },
                      },
                      model: {
                        value: _vm.query.clientId,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "clientId", $$v)
                        },
                        expression: "query.clientId",
                      },
                    }),
                    _c("b-form-datepicker", {
                      staticClass: "el-def",
                      attrs: {
                        id: "datepicker-placeholder1",
                        placeholder: "게시기간(Fr)",
                      },
                      model: {
                        value: _vm.query.startDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "startDate", $$v)
                        },
                        expression: "query.startDate",
                      },
                    }),
                    _c("label", { staticClass: "ml-25 mr-50" }, [_vm._v("~")]),
                    _c("b-form-datepicker", {
                      staticClass: "el-def",
                      attrs: {
                        id: "datepicker-placeholder2",
                        placeholder: "게시기간(To)",
                      },
                      model: {
                        value: _vm.query.endDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "endDate", $$v)
                        },
                        expression: "query.endDate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-end",
                    attrs: { cols: "12", md: "2" },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-search",
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.fetchEmailAsks()
                          },
                        },
                      },
                      [_vm._v(" 조회 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "div",
            { staticClass: "m-2" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-start",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c("h5", { staticClass: "mb-0 ml-50" }, [
                        _vm._v(
                          " 콘텐츠 리스트 [" +
                            _vm._s(_vm.totalRecords) +
                            "] 건 "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "primary-key": "liveInfoId",
              items: _vm.drugInfoList,
              fields: _vm.tableColumns,
              responsive: "",
              "sticky-header": "",
              selectable: "",
              "select-mode": "single",
            },
            on: { "row-selected": _vm.onRowSelectedEmailAsk },
            scopedSlots: _vm._u([
              {
                key: "cell(title)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v(" " + _vm._s(data.value) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "div",
            { staticClass: "m-2" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                      attrs: { cols: "12", md: "10" },
                    },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "EditIcon", size: "19" },
                      }),
                      _c("h4", { staticClass: "mb-0 ml-50" }, [
                        _vm._v(" 상세 "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-end",
                      attrs: { cols: "12", md: "2" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-search",
                          attrs: { variant: "outline-dark" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.excelDownload()
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "DownloadIcon" },
                          }),
                          _c("span", [_vm._v(" 다운로드")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-table", {
            staticClass: "position-relative",
            staticStyle: { "max-height": "330px" },
            attrs: {
              "primary-key": "liveInfoId",
              items: _vm.emailAskList,
              fields: _vm.detailTableColumns,
              responsive: "",
              "sticky-header": "",
              selectable: "",
              "select-mode": "single",
            },
            scopedSlots: _vm._u([
              {
                key: "cell(drugAskDetail)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v(" " + _vm._s(data.value) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }